body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .button {
    height: 56px;
    border-radius: 0;
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: auto;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }

  .button-white {
    width: 60px;
    height: 60px;
    border-radius: 0;
    background: #e4e4e4;
    margin: 10px;
  }
  .MuiButton-text:hover,
  .button-white:hover {
    background: #e4e4e4;
  }

  .lds-hourglass {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-hourglass:after {
    content: " ";
    display: block;
    border-radius: 50%;
    width: 0;
    height: 0;
    margin: 8px;
    box-sizing: border-box;
    border: 32px solid #000;
    border-color: #000 transparent #000 transparent;
    animation: lds-hourglass 1.2s infinite;
  }

  @keyframes lds-hourglass {
    0% {
      transform: rotate(0);
      animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    }
    50% {
      transform: rotate(900deg);
      animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    }
    100% {
      transform: rotate(1800deg);
    }
  }

  // .bcuploader-root {position: relative;}

  .bcuploader-error.is-hidden,
  .bcuploader-preview.is-hidden {
    display: none;
  }

  .bcuploader-preview.is-shown {
    position: fixed;
    z-index: 2;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
  }
  .bcuploader-preview_overlay {
    position: absolute;
    z-index: 1;
    background: black;
    opacity: 0.4;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
  }
  .bcuploader-preview_player {
    position: absolute;
    z-index: 2;
    border: none;
    top: 20px;
    height: calc(100% - 40px);
    left: 20px;
    width: calc(100% - 40px);
  }
  .bcuploader-preview_close-button {
    position: absolute;
    z-index: 2;
    top: 0;
    right: 0;
    cursor: hand;
    background: black;
    color: white;
    font-size: 14px;
  }

  .bcuploader-error,
  .bcuploader-landing {
    background-color: #fff;
    border: 1px dashed #e1e1e8;
    border-radius: 4px;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
    margin-right: 15px;
    height: 275px;
    text-align: center;
  }
  .bcuploader-landing.is-dragover {
    border: 5px solid #9d9db4;
    padding: 26px;
    background-color: #d4e1f7;
  }
  .bcuploader-landing_heading {
    display: block;
    font-size: 2em;
    margin-bottom: 15px;
    font-style: normal;
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
  }
  .bcuploader-landing_file-input {
    display: block;
    margin: auto;
    visibility: hidden;
  }

  .bcuploader-video-container {
    position: relative;
    display: block;
    margin-top: 15px;
  }

  .bcuploader-video {
    margin-top: 32px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 4px 50px rgba(0, 0, 0, 0.1);
    padding: 32px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 100%;
    box-sizing: border-box;
    margin: auto;
    margin-right: 15px;
  }
  .bcuploader-video:after {
    content: "";
    display: table;
    clear: both;
  }
  .bcuploader-video.is-pending {
  }
  .bcuploader-video.is-started {
  }
  .bcuploader-video.is-transcoding {
  }
  .bcuploader-video.is-preview {
  }

  .bcuploader-error,
  .bcuploader-video.is-error {
    color: #a94442;
    background-color: #f2dede;
    border-color: #ebccd1;
  }

  .bcuploader-video_file-name {
    position: relative;
    z-index: 1;
    display: block;
    float: left;
    text-align: left;
  }

  .bcuploader-video_label {
    position: relative;
    z-index: 1;
  }
  .is-preview .bcuploader-video_label {
    cursor: pointer;
    color: blue;
  }
  .bcuploader-video_progress-bar {
    height: 16px;
    background-color: #000;
  }

  .bcuploader-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 256px;
    height: 48px;
    background-color: #000;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    text-align: center;
    color: #ffffff;
    border: none;
  }

  .bcuploader-buttons {
    display: flex;
    justify-content: space-around;
    width: 100%;
    margin: 0 auto;
  }

  .bcuploader-heading {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 26px;
  }

  .bcuploader-title {
    color: #000;
    font-style: normal;
    font-weight: bold;
    font-size: 32px;
    line-height: 31px;
  }

  .bcuploader-button-white,
  .bcuploader-button {
    height: 56px;
    width: 160px;
    margin: 0;
    margin-left: 20px;
    font-style: normal;
    font-weight: bold;
    font-size: 14px;
    line-height: 14px;
    cursor: pointer;
    border-radius: 0;
    text-align: center;
    border: none;
  }
  .bcuploader-button {
    background-color: #000;
    color: #fff;
    text-align: center;
    padding: auto;

    &:hover {
      color: #fff;
      background-color: #000;
    }
  }
  .bcuploader-button-white {
    color: #000;
    padding: auto;
    border-radius: 0;
    background-color: #f5f5f5;
  }

  .bcuploader-block {
    margin-top: 28px;
    display: flex;
    width: 100%;
  }

  .bcuploader-block-bottons {
    display: flex;

    .bcuploader-button-white {
      margin-left: 0;
    }
  }

  .bcuploader-information {
    display: flex;
    flex-direction: column;
    font-style: normal;
    font-size: 16px;
    line-height: 16px;
    margin: 16px 0;
    margin-right: 44px;

    strong {
      font-weight: bold;
      margin-bottom: 13px;
    }
  }

  .bcuploader-uploading {
    font-weight: 300;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 7px;
    display: flex;
    align-items: center;
  }

  .bcuploader-btns {
    display: flex;
  }

  .bcuploader-input {
    margin: 12px 0;
    width: 100%;
    max-width: 340px;
    border: 1px solid #c4c4c4;
    box-sizing: border-box;
    padding: 20px;
    height: 56px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 15px;
    margin-top: 24px;
    outline: none;
  }
  .bcuploader-input:focus-visible {
    border: 2px solid #000;
    border-radius: 0;
  }

  .MuiPopover-paper {
    margin-top: 45px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: inherit;
  text-decoration: none;
}

.spinner {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0.7;
}

.alert-save-video {
  color: rgb(30, 70, 32);
  background-color: rgb(237, 247, 237);
  font-size: 0.875rem;
  border-radius: 4px;
  padding: 14px 24px;
  position: fixed;
  left: 50%;
  right: auto;
  bottom: 24px;
  transform: translateX(-50%);

  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='%234caf50' class='MuiSvgIcon-root MuiSvgIcon-fontSizeInherit' focusable='false' viewBox='0 0 24 24' aria-hidden='true'%3E%3Cpath d='M20,12A8,8 0 0,1 12,20A8,8 0 0,1 4,12A8,8 0 0,1 12,4C12.76,4 13.5,4.11 14.2, 4.31L15.77,2.74C14.61,2.26 13.34,2 12,2A10,10 0 0,0 2,12A10,10 0 0,0 12,22A10,10 0 0, 0 22,12M7.91,10.08L6.5,11.5L11,16L21,6L19.59,4.58L11,13.17L7.91,10.08Z'%3E%3C/path%3E%3C/svg%3E");
  background-repeat: no-repeat no-repeat;
  background-position: 9px center;
  background-size: 19px;
  padding-left: 38px;
  fill: green;
}

.bcuploader-landing-uploadText {
  display: block;
  color: #b3b3b3;
  margin: auto;
  font-size: 22px;
  width: 238px;
  font-weight: bold;
  background-image: url("data:image/svg+xml,%3Csvg width='32' height='28' viewBox='0 0 32 28' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M15.6396 14V26' stroke='%23b3b3b3' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M26.3789 22.52C28.9471 21.0616 30.2392 17.975 29.5177 15.0223C28.7962 12.0695 26.2447 10.002 23.3197 10H21.7069C20.6459 5.7251 17.166 2.57305 12.9545 2.07215C8.74303 1.57125 4.67062 3.82506 2.71181 7.7408C0.752999 11.6565 1.31277 16.4247 4.11971 19.7333' stroke='%23b3b3b3' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3Cpath d='M20.7595 19.3333L15.6395 14L10.5195 19.3333' stroke='%23b3b3b3' stroke-width='3' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat no-repeat;
}
.bcuploader-landing-orText {
  display: block;
  margin-top: 50px;
}

.video-js {
  width: 70%;
  float: left;
}
.vjs-playlist {
  width: 30%;
  float: right;
}
